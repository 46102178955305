<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">

            <v-row align-items="center">
              <v-col>
                <v-switch
                  v-model="asap"
                  :label="'Piorytet zadania: '+(asap?'ASAP':'Normalny')"
                  color="primary"
                  inset
                ></v-switch>
              </v-col>
              <v-col class="flex_end">
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="add_task()"
                >
                  Dodaj
                </v-btn>
              </v-col>
            </v-row>

        </v-card>
        
        <v-form v-model="valid">
          <v-card
            style="transition: .5s"
            :dark="asap"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="name"
                    label="Tytuł zadania"
                  ></v-text-field>

                  <v-textarea
                    v-model="description"
                    label="Opis / Notka"
                  ></v-textarea>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="selected_user"
                        :items="sprint.users"
                        item-text="name"
                        item-value="id"
                        no-data-text="Brak wyników"
                        class="classic_select"
                        label="Przypisani użytkownicy"
                        :rules="$store.state.rules.not_null"
                        chips
                        small-chips
                        multiple
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="expected_time"
                        label="Przewidywany czas na zadanie (godziny)"
                        type="number"
                      ></v-text-field>
                      <!-- <v-select
                        v-model="selected_status"
                        :items="status"
                        class="classic_select"
                        chips
                        label="Status"
                      ></v-select> -->
                    </v-col>
                  </v-row>

                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>

      </v-col>
    </v-row>
    
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    id: 0,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista projektów',
        disabled: false,
        to: '/projekty',
      },
      {
        text: 'Projekt',
        disabled: false,
        to: '/projekt',
      },
      {
        text: 'Etap',
        disabled: false,
        to: '/sprint',
      },
      {
        text: 'Nowe zadanie',
        disabled: true,
        to: '/projekt-nowy',
      },
    ],

    name: '',
    description: '',
    selected_user: [],
    expected_time: '',
    selected_status: 'Nowy',
    status: [
      'Nowy',
      'W trakcie',
      'Rozwiązany'
    ],
    asap: false,

    sprint: {}
  }),
  methods: {
    add_task() {
      this.$store.commit('loader');

      let formData = new FormData();
      formData.append("sprint_id", this.id);
      formData.append("name", this.name);
      formData.append("description", this.description);
      formData.append("expected_time", this.expected_time);
      formData.append("status", 'Nowy');
      if(this.asap) formData.append("priority", 'asap');
      else formData.append("priority", 'normalny');
      if(this.selected_user) {
        for(let i=0 ; i<this.selected_user.length ; i++)
          formData.append("assign["+i+"]", this.selected_user[i]);
      }

      this.$axios({url: this.$store.state.api +'/task', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          this.$router.push('/sprint/'+this.id);
          this.$store.commit("snackbar", {
            text: "Dodano zadanie",
            color: "primary",
            btn_color: "white"
          });
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    },
  },
  mounted(){
    this.id = this.$route.params.id;
    this.breadcrumb[3].to = '/sprint/'+this.id;

    this.$store.commit('loader');

    // Tylko dla podmiany linku do projektu
    this.$axios({url: this.$store.state.api +'/sprint_task/'+this.id, data: {}, method: 'GET' })
      .then(resp => {
        this.sprint = resp.data.sprint; 
        this.breadcrumb[2].to = '/projekt/'+this.sprint.project_id;
        // console.log(resp.data);
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then(()=> {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
</style>